import { HeadFC } from 'gatsby'
import React from 'react'
import list from '../../content/lists/apps-off-the-month.json'
import SEO from '../components/SEO'

import BestApps from '../pageTypes/BestApps'

interface Props {}

const Page: React.FC<Props> = () => {
    return <BestApps apps={list} type="month" />
}

export const Head: HeadFC = (props) => {
    const { location } = props

    const title = 'Best APPS of the MONTH'
    const description = 'We collected the best APPS that came out this MONTH'
    const summary = description

    return (
        <SEO
            description={description}
            location={location.pathname}
            summary={summary}
            title={title}
        />
    )
}

export default Page
